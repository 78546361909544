import request from '@/api/request'

// 表单设计列表
export function reqFDlist (params) { return request({ url: '/workflowform/page', params: params }) }

// 应用列表
export function reqAplictionlist (params) { return request({ url: '/application/list', params: params }) }

// 租户列表
export function reqTenantslist (params) { return request({ url: '/tenant/simple/list', params: params }) }

// 新增
export function reqFDadd (params) { return request({ url: '/workflowform', params, method: 'post' }) }

// 详情
export function reqFDdetail (params) { return request({ url: '/workflowform/:id', params }) }

// 编辑
export function reqFDedit (params) { return request({ url: '/workflowform', params, method: 'PUT' }) }

// 删除
export function reqFDdel (params) { return request({ url: '/workflowform/:id', params, method: 'delete' }) }
