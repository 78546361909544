<template>
  <div class="formDesign">
    <TableWrap @callback="handlePagination" :total="total">
        <template slot="tablesearch">
            <SearchWrap :searchOptions="searchOptions" inline @callback="handleSearchCallback"></SearchWrap>
        </template>
        <template slot="tableOperate">
           <el-button type="primary" size="small" @click="handleCreate">新增</el-button>
        </template>
        <el-table :data="tableData" :header-cell-style="{background:'#FAFAFA'}" v-loading="loading">
            <el-table-column prop="name" label="表单名称"> </el-table-column>
            <el-table-column prop="code" label="表单编码"> </el-table-column>
            <el-table-column prop="tenantName" label="租户名称"> </el-table-column>
            <el-table-column prop="appName" label="应用名称"> </el-table-column>
            <el-table-column label="状态">
                 <template v-slot="scope">
                    {{  scope.row.status===0?'有效':'无效' }}
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间"> </el-table-column>
            <el-table-column prop="createTime" label="修改日期"> </el-table-column>
            <el-table-column label="操作" width="210" align="center">
                <template v-slot="scope">
                    <el-button type="text" size="small" plain @click="handleEdit(scope.row.id)">编辑</el-button>
                    <el-button type="text" size="small" plain @click="handlePreview(scope.row)">预览</el-button>
                    <el-button type="text" size="small" plain @click="handleDesign(scope.row.code)">设计</el-button>
                    <el-button type="text" size="small" plain @click="handleDelete(scope.row.id)">
                        <span style="color:#F5222D">
                            删除
                        </span>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </TableWrap>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose">
      <iframe height="100%" width="100%" :src="src" frameborder="0"></iframe>
    </el-dialog>
  </div>
</template>

<script>
// 表单设计列表
import tableMixIn from '@/mixIn/tableMixIn'
import { reqFDlist, reqFDdel } from '@/api/platform/formDesign'
// import Cookies from 'js-cookie'
export default {
    name: 'formDesign',
    mixins: [tableMixIn],
    data () {
        return {
            searchOptions: [
                { type: 'input', key: 'name', label: '表单名称' }
            ],
            tableData: [
                { name: 'x', codeO: 'y', codeA: 'z', sqlN: 'k', eD: '2020-1-9', cD: '2019-1-5' }
            ],
            total: 0,
            reqTableData: reqFDlist,
            isShow: false,
            dialogVisible: false,
            src: ''
        }
    },
    created () {
        this.getTableData()
    },
    methods: {
        // 新增
        handleCreate () {
            this.$router.push({
                name: 'cedFormDesign',
                params: { type: 'create' },
                query: { title: '新增' }
            })
        },
        // 删除
        handleDelete (id) {
            this.$confirm('删除后不可恢复，确定要删除吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                reqFDdel({ id }).then((res) => {
                    if (res.code === 0) {
                        this.$message.success('删除成功')
                        this.getTableData()
                    }
                })
            })
        },
        handleEdit (id) {
            this.$router.push({
                name: 'cedFormDesign',
                params: { type: 'edit' },
                query: { id: id, title: '编辑' }
            })
        },
        handleDesign (code) {
            this.$router.push({
                name: 'cedFormDesign',
                params: { type: 'design' },
                query: { code: code, title: '' }
            })
        },
        handlePreview (row) {
            // this.dialogVisible = true
            // this.src = `http://192.168.0.4:4011/#/formPreview?token=${Cookies.get('token')}&code=${row.code}&id=${row.id}`
            this.$router.push({
                name: 'cedFormDesign',
                params: { type: 'preview' },
                query: { code: row.code, title: '', id: row.id, viewTag: 1 }
            })
        },
        handleClose () {
            this.dialogVisible = false
            this.src = ''
        }
    }
}
</script>

<style lang="scss">
.formDesign{
  .el-dialog{
    height: 100%;
  }
  .el-dialog__body{
    height: 80%;
  }
}
</style>
